body {
  background-color: #F9F8F8;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Raleway', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body { 
  font-family: "Raleway", sans-serif; 
  font-weight: 900;
}

a {
  text-decoration: none;
  color: #0F3D54;
}
a:hover {
  filter: brightness(2);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

.container {
  width: 100%;
  margin-right: auto !important;
  margin-left: auto !important;

}

@media (min-width: 640px)	{
  .container {
      max-width: 640px;
  }
}
@media (min-width: 768px)	{
  .container {
      max-width: 768px;
  }
}
@media (min-width: 1024px)	{
  .container {
      max-width: 1024px;
  }
}
@media (min-width: 1280px)	{
  .container {
      max-width: 1280px;
  }
}
@media (min-width: 1536px)	{
  .container {
      max-width: 1536px;
  }
}

@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ }
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ }
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }

