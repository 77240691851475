.logo {
    margin-top:-40px;
    width: 190px;
    background-color: #F9F8F8;
    padding: 0 0px 0 10px;
}

.mobileNav {
    display: flex;
    width: 100vw;
    justify-content: space-around;
    align-items: center;
}
.mobileLogo {
    width: 150px;
}
.hamburger {
    width: 30px;
}

.containerCenter {
    position: relative;
    z-index: 2;
    height: 70px;
    margin: 28px 0;
    width: 100vw;
    display: flex;
    justify-content: center;
}

.navBlock {
    font-weight: 600;
    color: #0F3D54;
    font-size: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
}
    .navItem {
        color: #0F3D54;
        height: 25px;;
        font-size: 22px;
        text-decoration: none;
        border: none;
        font-weight: bold;
        background: none;
        cursor: pointer;
        transition: all .1s ease-out;
    }
        .navItem:hover {
        }

    .navItemLogo {
        color: #0F3D54;
        height: 25px;;
        font-size: 22px;
        text-decoration: none;
        border: none;
        font-weight: bold;
        background: none;
        cursor: pointer;
        transition: all .1s ease-out;
    }

    .navItemLogo:hover {
        scale: 1.1;
        filter: none !important;
    }

.dropdownElement {
    font-size: 25px;
    z-index: 4;
}


.contactUs {
    position: absolute;
    right: 20px;
    top: 15px;
}

.dropdownMobile {
    display: none;
}

@media (max-width: 1000px) {
    .dropdownMobile {
        display: flex;
    }
    .navBlock {
        display: none;
        flex-direction: column;
    }
}